const btnNavegacion = document.querySelector(".iconoBurger");
const btnNavegacionClose = document.querySelector(".iconoClose");
const navegacion = document.querySelector(".sidebar");

function scrollNav() {
    const enlaces = document.querySelectorAll(".navegacion");

    enlaces.forEach(enlace => {
        enlace.addEventListener("click", function(e) {
        e.preventDefault();
        const seleccionScroll = e.target.attributes.href.value;
        const seleccion = document.querySelector(seleccionScroll);
        seleccion.scrollIntoView({ behavior: "smooth" });

        if(navegacion.classList.contains) {
            btnNavegacion.classList.toggle("activo");
            btnNavegacionClose.classList.toggle("activo")
            navegacion.classList.toggle("activo");
        }
        });
      
    })
    
}

eventos();
function eventos() {
    document.addEventListener("DOMContentLoaded", scrollNav)
    document.addEventListener("DOMContentLoaded", devolver)
    btnNavegacion.addEventListener("click", mostrarNavegacion);
    btnNavegacionClose.addEventListener("click", mostrarNavegacion);
}

function mostrarNavegacion() {
    btnNavegacion.classList.toggle("activo");
    btnNavegacionClose.classList.toggle("activo")
    navegacion.classList.toggle("activo");
}
function devolver() {
    
    window.onscroll = function() {
        if(document.documentElement.scrollTop > 1100) {
            document.querySelector(".devolver-container").classList.add("show");
        } else {
            document.querySelector(".devolver-container").classList.remove("show");
        }
    }

    document.querySelector(".devolver-container").addEventListener("click", () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });
}
